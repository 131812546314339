import { PlanServiceStatus, SubscriptionPlan } from 'types/plan';
import { useCallback, useEffect, useMemo } from 'react';
import useSelectSubscriptionPlan from '../../hooks/useSelectSubscriptionPlan';
import useGetCustomerProducts from 'pages/SubscriptionRenewal/SelectPlan/hooks/useGetCustomerProducts';
import useGetCustomerProductUpgrades from 'pages/SubscriptionRenewal/SelectPlan/hooks/useGetCustomerProductUpgrades';
import { PlanAttributes, PlanResponse, CustomerProductUpgradesResponse, Addon } from 'types/request';
import useIsOnMonthlyBilling from 'pages/SubscriptionRenewal/hooks/useIsOnMonthlyBilling';

const generatePriceDescription = (initialPrice: number, recurringPrice: number, monthlyPrice: number, isOnMonthlyBilling: boolean) => {
  const recurringPriceString = 
    isOnMonthlyBilling 
      ? (monthlyPrice != null ? `additional $${monthlyPrice} / monthly` : '') 
      : (recurringPrice != null ? `additional $${recurringPrice} / per service` : '');
  return `${initialPrice != null ? '$' + initialPrice + ' initial & ' : ''}${recurringPriceString}`;
};

const generateUpgradeDescription = (nextPlan?: PlanResponse) => {
  return nextPlan ? `upgrade to ${nextPlan.attributes.name} or higher` : undefined;
};

const getStatus = (productId: number, planAttributes: PlanAttributes) => {
  if (planAttributes.products.includes(productId)) {
    return PlanServiceStatus.Included;
  }
  if (!planAttributes.products.includes(productId) && !planAttributes.addons.some(addon => addon.attributes.product_id === productId)) {
    return PlanServiceStatus.Disabled;
  }
  return PlanServiceStatus.Enable;
};

export interface IPurchasedInfo {
  product_id: number,
  purchased?: boolean
}

const getPurchasedInfo = (customerProductUpgrades: CustomerProductUpgradesResponse): IPurchasedInfo[] => {
  const result: IPurchasedInfo[] = [];
  customerProductUpgrades.forEach((upgradeInfo: PlanResponse) => {
    upgradeInfo.attributes.addons.forEach((addon: Addon) => {
      result.push({
        product_id: addon.attributes.product_id,
        purchased: addon.attributes.purchased
      })
    })
  })
  return result
}


const usePlanList = () => {
  const { isLoading: isLoadingCustomerProducts, data: customerProducts } = useGetCustomerProducts();

  const { isLoading: isLoadingCustomerProductUpgrades, data: customerProductUpgrades } = useGetCustomerProductUpgrades();
  const { selectedPlan, onSelect } = useSelectSubscriptionPlan();
  const { isOnMonthlyBilling, isOnMonthlyBillingLoading } = useIsOnMonthlyBilling();

  const generateAddons = useCallback(
    (attributes: PlanAttributes, nextPlan?: PlanResponse) => {
      return (
        customerProducts?.map(product => {
          const pricing = attributes.addons.find(addon => addon.attributes.product_id === +product.id)?.attributes ?? product.attributes;

          const status = getStatus(+product.id, attributes);
          const description =
            status === PlanServiceStatus.Disabled
              ? generateUpgradeDescription(nextPlan)
              : generatePriceDescription(
                pricing.initial_price, 
                pricing.recurring_price, 
                pricing.monthly_price,
                isOnMonthlyBilling
              );
          return {
            id: product.id,
            addonId: product.id,
            name: product.attributes.name,
            initialTreatmentPrice: pricing.initial_price,
            recurringPrice: pricing.recurring_price,
            monthlyPrice: pricing.monthly_price,
            iconSrc: product.attributes.image,
            description,
            status,
            is_recurring: product.attributes.is_recurring
          };
        }) || []
      );
    },
    [customerProducts, isOnMonthlyBilling]
  );

  const currentProductUpgrade = useMemo(
    () => customerProductUpgrades?.find(productUpgrade => productUpgrade.attributes.is_current_plan),
    [customerProductUpgrades]
  );

  const planList = useMemo<SubscriptionPlan[]>(() => {
    const nextPlan = currentProductUpgrade
      ? customerProductUpgrades?.find(productUpgrade => productUpgrade.attributes.order > currentProductUpgrade?.attributes.order)
      : undefined;
    return (
      customerProductUpgrades?.map(({ attributes }) => {
        return {
          id: attributes.plan_id.toString(),
          order: attributes.order,
          name: attributes.name,
          pricePerService: attributes.recurring_price,
          monthlyPrice: attributes.monthly_price,
          initialTreatmentPrice: attributes.initial_price,
          treatmentFrequency: attributes.frequency,
          agreementLength: attributes.agreement_length,
          addons: generateAddons(attributes, nextPlan),
        };
      }) || []
    );
  }, [customerProductUpgrades, generateAddons]);

  const userPlan = useMemo(
    () => planList?.find((plan: SubscriptionPlan) => +plan.id === currentProductUpgrade?.attributes.plan_id), 
    [planList, customerProductUpgrades]
  );

  const isLoading = useMemo(
    () => isLoadingCustomerProducts || isLoadingCustomerProductUpgrades || isOnMonthlyBillingLoading, 
    [isLoadingCustomerProducts, isLoadingCustomerProductUpgrades, isOnMonthlyBillingLoading]
  );

  useEffect(() => {
    if (selectedPlan === undefined && userPlan && customerProducts) {
      onSelect(userPlan);
    }
  }, [userPlan, customerProducts, onSelect, selectedPlan]);

  return {
    isLoading,
    planList,
    userPlan,
    getPurchasedInfo,
  };
};

export default usePlanList;
