import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { customerInfoDataState } from 'app-recoil/atoms/customer-info';
import { useFeatureFlag } from 'configcat-react';

const useIsOnMonthlyBilling = () => {
    const { value: isMonthlyBillingEnabled, loading: isMonthlyBillingEnabledLoading } = useFeatureFlag('isMonthlyBillingEnabled', false);

    const customerInfoData = useRecoilValue(customerInfoDataState);

    const isOnMonthlyBilling = useMemo<boolean>(
      () => isMonthlyBillingEnabled && customerInfoData?.isOnMonthlyBilling,
      [isMonthlyBillingEnabled, customerInfoData]
    );
    
    return {isOnMonthlyBilling, isOnMonthlyBillingLoading: isMonthlyBillingEnabledLoading || !customerInfoData};
};

export default useIsOnMonthlyBilling;
